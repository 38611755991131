import React, { FC, useContext, useEffect, useState } from 'react';
import { InputField } from '../../../../ui-library/components/form-elements/CustomTextField';
import { Dropdown } from '../../../../ui-library/components/dropdowns/core-dropdown';
import { FileUpload } from '../../../../ui-library/components/form-elements/file-upload/FileUpload';
import { RadioButtonSelect } from '../../../../ui-library/components/form-elements/RadioButtonSelect';
import { Box, Checkbox, Divider, FormControlLabel, FormHelperText, FormLabel, Stack, Typography } from '@mui/material';
import { Formik } from 'formik';
import { ActionButton } from '../../../../ui-library/components/buttons/action-button';
import { SecurityTypeOptions } from '../../constants/DealsConstant';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../../../contexts/authContext';
import DealsManager from '../../manager/DealsManager';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { FormPlaceholder } from '../../../../ui-library/components/placeholders/form-placeholder/FormPlaceholder';
import { formPlaceholderConfig } from '../../constants/LaunchDealConstant';
import moment from 'moment';
import { getColor } from '../../../../ui-library/colors';
import { API_STATUS } from '../../../common/constants/CommonConstants';
import LeadInvestorList from './lead-investor-list/LeadInvestorsList';
import PostHogManager from '../../../../analytics/postHogManager';
import PHE from '../../../common/constants/PostHogEvents';

const SchemeInfoForm: FC<any> = ({ apiStatus, setApiStatus, viewMode = false }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const authContext = useContext(AuthContext);
  const dealManager = new DealsManager(authContext);
  const create_startup_id = localStorage.getItem('create_startup_id');

  const initialValuesConstant = {
    syndicate_name: '',
    reg_startup_name: '',
    startup_service: '',
    pre_money_valuation: '',
    total_ask: '',
    commitment_via_lv: '',
    round_type: null,
    share_price_with_text: '',
    securities_description: '',
    conversion_terms: '',
    lv_transaction_fee: '',
    fund_carry: '',
    total_lead_carry: '',
    lead_investor_list: [],
    hurdle_rate: '',
    commit_time_window: 1,
    last_date_to_consent: moment().add(1, 'days').format('DD MMM YYYY'),
    draft_agreement_file: null,
    send_auto_drawdown: null,
    dpiit: null,
    right_under_negotiations: '',
    lv_team_comments: '',
    startup_transaction: false
  };

  const [initialValues, setInitialValues] = useState<any>(initialValuesConstant);
  const [submittingStatus, setSubmittingStatus] = useState(false);
  const [savingStatus, setSavingStatus] = useState(false);
  const [completeForm, setCompleteForm] = useState(false);

  const formValidation = Yup.object().shape({
    syndicate_name: Yup.string().required('This field is Required'),
    reg_startup_name: Yup.string().required('This field is Required'),
    startup_service: Yup.string().required('This field is Required'),
    pre_money_valuation: Yup.number().required('This field is Required'),
    total_ask: Yup.number().required('This field is Required'),
    commitment_via_lv: Yup.number()
      .required('This field is Required')
      .test('is-less-than-total-ask', 'Commitment via LV cannot be greater than Total Ask', function (value) {
        const { total_ask } = this.parent;
        return value === undefined || value <= total_ask;
      })
      .test('is-greater-than-minimum', 'Commitment via LV cannot be less than 25 Lakhs', function (value) {
        return value === undefined || value >= 2500000;
      })
      .test('is-less-than-maximum', 'Commitment via LV cannot be greater than 10 Crores', function (value) {
        return value === undefined || value <= 100000000;
      }),
    round_type: Yup.object().required('This field is Required'),
    share_price_with_text: Yup.string().when('round_type', {
      is: (val: any) => val?.value !== 'equity',
      then: (schema) => schema.required('This field is Required'),
      otherwise: (schema) => schema.notRequired()
    }),
    securities_description: Yup.string().required('This field is Required'),
    lv_transaction_fee: Yup.number().required('This field is Required'),
    fund_carry: Yup.number().required('This field is Required'),
    total_lead_carry: Yup.number().required('This field is Required'),
    lead_investor_list: Yup.array().test('my_lead_carry_test', (value: any, validationContext) => {
      const total_carry = value.reduce((sum: any, item: any) => sum + Number(item.lead_carry), 0.0);

      const EPSILON = 1e-6; // Define a small tolerance

      const checkLeadAllocation = () => {
        let msg = '';

        if (
          validationContext.parent.total_lead_carry <= 0.5 ||
          Math.abs(total_carry - validationContext.parent.total_lead_carry) <= EPSILON
        ) {
          return true;
        }

        if (total_carry < validationContext.parent.total_lead_carry) {
          msg = 'Lead Carry is left to be allocated';
          return validationContext.createError({ message: msg });
        }

        msg = 'Lead Carry is exceeding total lead carry';
        return validationContext.createError({ message: msg });
      };

      const checkResult = checkLeadAllocation();
      return validationContext.parent.total_lead_carry ? checkResult : true;
    }),
    commit_time_window: Yup.number().required('This field is Required'),
    last_date_to_consent: Yup.date().required('This field is Required'),
    send_auto_drawdown: Yup.string().required('This field is Required'),
    dpiit: Yup.string().required('This field is Required'),
    conversion_terms: Yup.string().when('round_type', {
      is: (val: any) => val?.value !== 'equity',
      then: (schema) => schema.required('This field is Required'),
      otherwise: (schema) => schema.notRequired()
    }),
    startup_transaction: Yup.bool().oneOf([true], 'You need to accept the terms and conditions')
  });

  const getSchemeInfoFormData = async () => {
    try {
      setApiStatus(API_STATUS.LOADING);
      const response = await dealManager.fetchSchemeInfoFormData(create_startup_id ?? id);
      if (response) setInitialValues({ ...initialValues, ...response, startup_id: create_startup_id ?? id });
      setApiStatus(API_STATUS.SUCCESS);
      if (create_startup_id) localStorage.removeItem('create_startup_id');
    } catch (e: any) {
      toast.error(e.message || 'An error occurred while fetching data');
      setApiStatus(API_STATUS.ERROR);
    }
  };

  useEffect(() => {
    if (id || create_startup_id) getSchemeInfoFormData();
  }, [id, create_startup_id]);

  const submitStartupData = async (values: any) => {
    try {
      setSubmittingStatus(true);
      const saveSchemeDataResponse = await dealManager.updateSchemeData(
        {
          current_action: initialValues?.is_scheme ? 'edit' : 'generate',
          revenue_check: initialValues?.revenue_check ? 'true' : 'false',
          ...values
        },
        false
      );
      const response = await dealManager.submitForApproval(values?.startup_id);
      PostHogManager.trackEvent(PHE.SubmitToLvSchemeInfoSuccess);
      navigate(`/deals/${values?.startup_id}`);
      toast.success(response?.details);
    } catch (e: any) {
      PostHogManager.trackEvent(PHE.SubmitToLvSchemeInfoFailed);
      toast.error(e.message || 'An error occurred while submitting data');
    } finally {
      setSubmittingStatus(false);
    }
  };

  const saveSchemeData = async (values: any, isSavingInDraft: boolean) => {
    try {
      setSavingStatus(true);
      const response = await dealManager.updateSchemeData(
        {
          current_action: initialValues?.is_scheme ? 'edit' : 'generate',
          revenue_check: initialValues?.revenue_check ? 'true' : 'false',
          ...values
        },
        isSavingInDraft
      );
      PostHogManager.trackEvent(PHE.SaveAsDraftSchemeInfoSuccess);
      setCompleteForm(response?.details?.in_complete_status === 'false');
      toast.success(response?.details?.message);
    } catch (e: any) {
      PostHogManager.trackEvent(PHE.SaveAsDraftSchemeInfoFailed);
      toast.error(e.message || 'An error occurred while saving data');
    } finally {
      setSavingStatus(false);
    }
  };

  const saveLeadInvestor = (setFieldValue: any, values: any) => {
    if (values && values.selected_lead_investor && values.lead_investor_list) {
      const filtered_lead_investor = values.lead_investor_list.filter(
        (item: any) => item.value !== values.selected_lead_investor.value
      );
      filtered_lead_investor.push({
        ...values.selected_lead_investor
      });
      setFieldValue('lead_investor_list', filtered_lead_investor);
    }
    // setOpenAddInvestorModal(false);
    setFieldValue('selected_lead_investor', null);
    // setLeadCarry(null);
  };

  const removeInvestor = (id: string, setFieldValue: any, leadInvestorList: any) => {
    const confirmation = window.confirm('Do you really want to remove this Investor?');
    if (leadInvestorList && confirmation) {
      const filtered_lead_investor = leadInvestorList.filter((item: any) => item.value !== id);
      setFieldValue('lead_investor_list', filtered_lead_investor);
    }
  };

  if (apiStatus === API_STATUS.LOADING)
    return (
      <Box width="60%">
        <FormPlaceholder elementsConfig={formPlaceholderConfig} />
      </Box>
    );

  return (
    <Formik
      validationSchema={formValidation}
      initialValues={initialValues}
      enableReinitialize
      onSubmit={submitStartupData}
      validateOnMount
    >
      {({ values, handleSubmit, setFieldValue, setTouched, isValid, errors, validateField, touched }) => {
        return (
          <Stack gap={2} width={'60%'} textAlign="left">
            <InputField
              fieldName="syndicate_name"
              id="syndicate_name"
              value={values?.syndicate_name}
              error={!!errors?.syndicate_name}
              label="Name of Syndicate"
              required
              disabled
            />
            <InputField
              fieldName="reg_startup_name"
              id="reg_startup_name"
              value={values?.reg_startup_name}
              error={!!errors?.reg_startup_name}
              label="Startup Entity Name as per Govt Records"
              required
              disabled
            />
            <InputField
              fieldName="startup_service"
              id="startup_service"
              value={values?.startup_service}
              error={!!errors?.startup_service}
              label="What is the business the Startup is engaged in (1 line description)?"
              required
              multiline
              minRows={2}
              disabled={viewMode}
              maxLength={100}
            />
            <InputField
              fieldName="pre_money_valuation"
              id="pre_money_valuation"
              value={values?.pre_money_valuation}
              error={!!errors?.pre_money_valuation}
              label="What is the Pre-Money Valuation?"
              required
              type="number"
              disabled={viewMode}
            />
            <InputField
              fieldName="total_ask"
              id="total_ask"
              value={values?.total_ask}
              error={!!errors?.total_ask}
              label="What is the total Round Size (LV + External)?"
              required
              type="number"
              disabled={viewMode}
            />
            <InputField
              fieldName="commitment_via_lv"
              id="commitment_via_lv"
              value={values?.commitment_via_lv}
              error={!!errors?.commitment_via_lv}
              label="What is the approx Round size on LV (via this scheme)?"
              required
              type="number"
              disabled={viewMode}
              onChange={(value: any) => {
                setFieldValue('securities_description', value / values?.share_price_with_text);
              }}
              helperText="If you have 1.2 Cr allocation, but might ask the startup to increase it to 1.4 Cr, please specify 1.5 Cr here. The scheme will be “up to” the amount you specify here. If the final amount ends up being below (within reason), that’s fine. If the final amount is higher, we will need to revise the scheme document and investors may need to reconsent."
            />

            <Box>
              <FormControlLabel
                required
                label="I confirm that this is a Primary Transaction (shares issued directly by company)."
                id="startup_transaction"
                checked={values?.startup_transaction}
                value={values?.startup_transaction}
                disabled={viewMode}
                onChange={(e: any) => {
                  setTouched({ ...touched, startup_transaction: true });
                  setFieldValue('startup_transaction', e.target.checked);
                }}
                control={<Checkbox />}
              />
              {touched?.startup_transaction && errors?.startup_transaction && (
                <FormHelperText error={true}>You need to accept the terms and conditions</FormHelperText>
              )}

              <Typography variant="caption_regular" pl="2rem">
                <span style={{ color: getColor('neutral', 600) }}>
                  "Note: LV AIF is not allowed to do secondary transaction (shares are purchased from shareholders) as
                  per SEBI regulation."
                </span>
              </Typography>
            </Box>

            <Dropdown
              fieldName="round_type"
              id="round_type"
              value={values?.round_type}
              options={SecurityTypeOptions}
              error={!!errors?.round_type}
              label="What is the Type of Security being issued?"
              required
              disabled={viewMode}
            />
            <InputField
              fieldName="share_price_with_text"
              id="share_price_with_text"
              value={values?.share_price_with_text}
              error={!!errors?.share_price_with_text}
              label="What is the price per share/security?"
              required={values?.round_type?.value !== 'equity'}
              onChange={(value: any) => {
                setFieldValue('securities_description', Number(values?.commitment_via_lv / value).toFixed(2));
              }}
              type="number"
              disabled={viewMode}
            />
            <InputField
              fieldName="securities_description"
              id="securities_description"
              value={values?.securities_description}
              error={!!errors?.securities_description}
              label="What is the approx # of shares/securities being issued to LV Angel Fund in this scheme?"
              type="number"
              disabled
              required
            />
            <InputField
              fieldName="conversion_terms"
              id="conversion_terms"
              value={values?.conversion_terms}
              error={!!errors?.conversion_terms}
              label="What are the Conversion Terms (for CCD or Convertible Note)?"
              multiline
              minRows={5}
              required={values?.round_type?.value !== 'equity'}
              disabled={viewMode}
            />
            <InputField
              fieldName="lv_transaction_fee"
              id="lv_transaction_fee"
              value={values?.lv_transaction_fee}
              error={!!errors?.lv_transaction_fee}
              label="What is LV Platform Fee %?"
              required
              type="number"
              helperText="Note: 2% + GST is LV’s standard Platform Fee. If you specify a different number, please mention why in the comments box at the bottom of this form."
              disabled={viewMode}
            />
            <InputField
              fieldName="fund_carry"
              id="fund_carry"
              value={values?.fund_carry}
              error={!!errors?.fund_carry}
              label="What is LV Carry %?"
              required
              type="number"
              helperText="Note: 2.5% is LV’s standard Carry. If you specify a different number, please mention why in the comments box at the bottom of this form."
              disabled={viewMode}
            />
            <InputField
              fieldName="total_lead_carry"
              id="total_lead_carry"
              value={values?.total_lead_carry}
              error={!!errors?.total_lead_carry}
              label="What is Lead Carry %?"
              required
              type="number"
              disabled={viewMode}
            />
            <Divider />
            <FormLabel>Lead Investors</FormLabel>
            <Stack sx={{ p: 2, background: '#F1F4F8' }}>
              <LeadInvestorList
                user="leadInvestor"
                isMandatory={false}
                carryError
                labelName="lead_investor_list"
                investorList={values?.lead_investor_list}
                removeInvestor={removeInvestor}
                setFieldValue={setFieldValue}
                saveLeadInvestor={saveLeadInvestor}
                values={values}
                innerText="Add Investor"
                errors={errors}
                removeIcon
                validateField={validateField}
                textinfo="Only your syndicate’s backers / admins who are registered with LV’s AIF can be Leads and we take their entity name to display in the scheme document and not the profile name."
              />
            </Stack>
            <Divider />

            <InputField
              fieldName="hurdle_rate"
              id="hurdle_rate"
              value={values?.hurdle_rate}
              error={!!errors?.hurdle_rate}
              label="What is Hurdle Rate % (optional)?"
              type="number"
              disabled={viewMode}
            />
            <InputField
              fieldName="commit_time_window"
              id="commit_time_window"
              value={values?.commit_time_window}
              error={!!errors?.commit_time_window}
              label="What is number of days to commit once the deal has been published?"
              required
              helperText="Note: this is enforced on the platform and your backers won't be able to commit to the deal beyond these many days."
              disabled={viewMode}
              onChange={(value: any) => {
                const dateObj = moment().add(value || 0, 'days');
                const formattedDate = dateObj.format('DD MMM YYYY');
                setFieldValue('last_date_to_consent', formattedDate);
              }}
              type="number"
            />
            <InputField
              type="date"
              fieldName="last_date_to_consent"
              id="last_date_to_consent"
              value={values?.last_date_to_consent}
              error={!!errors?.last_date_to_consent}
              label="What is the last date for Investors to consent to the Scheme Doc?"
              required
              disabled
              helperText="Note: this is not enforced on the platform. It is for documentation purpose only."
            />
            <FileUpload
              title="Upload Draft SHA"
              selectedFile={(file: any) => setFieldValue('draft_agreement_file', file)}
              value={values?.draft_agreement_file}
              error={!!errors?.draft_agreement_file}
              label="Upload Draft SHA"
              helperText="Note: Please upload the executed version of the document if available. If not, please upload a draft copy of the agreement for us to verify the round terms."
              width="auto"
              disabled={viewMode}
              accept={{
                'text/pdf': ['.pdf']
              }}
              hideDocumentVault
            />
            <RadioButtonSelect
              fieldName="send_auto_drawdown"
              id="send_auto_drawdown"
              value={values?.send_auto_drawdown}
              error={!!errors?.send_auto_drawdown}
              required
              formLabel="Do you want the investor to pay the investment amount immediately after commitment?"
              options={[
                { name: 'Yes', value: 'true', disabled: viewMode },
                { name: 'No', value: 'false', disabled: viewMode }
              ]}
              disabled={viewMode}
              onChange={(value: any) => {
                PostHogManager.trackEvent(PHE.InvestorToPayImmediateDrawdownYesNo);
              }}
            />
            <RadioButtonSelect
              fieldName="dpiit"
              id="dpiit"
              value={values?.dpiit}
              error={!!errors?.dpiit}
              required
              formLabel="Is the Startup recognized by DPIIT?"
              options={[
                { name: 'Yes', value: 'true', disabled: viewMode },
                { name: 'No', value: 'false', disabled: viewMode }
              ]}
              disabled={viewMode}
            />
            <InputField
              fieldName="right_under_negotiations"
              id="right_under_negotiations"
              value={values?.right_under_negotiations}
              error={!!errors?.right_under_negotiations}
              label="Rights still under Negotiation for this Scheme"
              multiline
              minRows={5}
              disabled={viewMode}
            />
            <InputField
              fieldName="lv_team_comments"
              id="lv_team_comments"
              value={values?.lv_team_comments}
              error={!!errors?.lv_team_comments}
              label="Add any Comments/Notes for LV"
              multiline
              minRows={5}
              disabled={viewMode}
            />
            <br />
            {!viewMode && (
              <Stack direction="row" gap={2} justifyContent="end">
                <ActionButton
                  isLoading={savingStatus}
                  disabled={savingStatus || viewMode}
                  onClick={() => {
                    saveSchemeData(values, true);
                  }}
                >
                  Save as Draft
                </ActionButton>
                <ActionButton
                  isLoading={submittingStatus}
                  disabled={submittingStatus || !isValid || viewMode}
                  onClick={() => {
                    if (isValid) handleSubmit(values);
                  }}
                >
                  Submit to LV for Review
                </ActionButton>
              </Stack>
            )}
          </Stack>
        );
      }}
    </Formik>
  );
};

export default SchemeInfoForm;
