import React, { useContext, useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { LvComplexTable } from '../../../ui-library/components/tables/lv-complex-table';
import { Breadcrumb } from '../../../ui-library/components/navs/breadcrumb';
import { GRID_CHECKBOX_SELECTION_FIELD } from '@mui/x-data-grid-pro';
import { ActionButton } from '../../../ui-library/components/buttons/action-button';
import FilterChip from '../../../ui-library/components/filter-chip/FilterChip';
import { CheckCircleOutline } from '@mui/icons-material';
import { LinkButton } from '../../../ui-library/components/buttons/link-button';
import ActionContainer from '../../common/components/ActionContainer';
import InviteBackerModal from '../modals/InviteBackerModal';
import BulkInviteBackerModal from '../modals/BulkInviteBackerModal';
import { AuthContext } from '../../../contexts/authContext';
import BackersManager from '../manager/BackersManager';
import { toast } from 'react-toastify';
import {
  BACKER_ONBOARDING_STATUS,
  BACKER_ONBOARDING_STATUS_KEY,
  ColumnObjectType,
  TYPE_OF_COMMUNICATION_KEY,
  backersColumnsData,
  breadcrumbData
} from '../constants/BackersConstant';
import { useNavigate } from 'react-router-dom';
import { TablePlaceholder } from '../../../ui-library/components/placeholders/table-placeholder/TablePlaceholder';
import { NoDataAvailable } from '../../../ui-library/components/error/NoDataAvailable';
import { API_STATUS } from '../../common/constants/CommonConstants';
import { IconConstant } from '../../common/constants/IconConstants';
import PostHogManager from '../../../analytics/postHogManager';
import PHE from '../../common/constants/PostHogEvents';

function Backers() {
  const authContext = useContext(AuthContext);
  const backersManager = new BackersManager(authContext);
  const navigate = useNavigate();

  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(API_STATUS.LOADING);
  const [submitApiStatus, setSubmitApiStatus] = useState(API_STATUS.SUCCESS);

  const getBackersData = async () => {
    try {
      setLoading(API_STATUS.LOADING);
      const result = await backersManager.getAllBackers();
      if (result) {
        setData(result);
      }
      setLoading(API_STATUS.SUCCESS);
    } catch (error: any) {
      toast.error(error);
      setLoading(API_STATUS.ERROR);
    }
  };

  useEffect(() => {
    getBackersData();
  }, []);

  const [selectedStatus, setSelectedStatus] = useState(BACKER_ONBOARDING_STATUS.ALL);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openInviteBackerModal, setOIBM] = useState<any>(false);
  const [openBulkInviteBackerModal, setOBIBM] = useState(false);
  const [reInvite, setReInvite] = useState(false);

  const setReInviteFalse = () => {
    setTimeout(() => setReInvite(false), 500);
  };
  const handleCloseInvite = () => {
    setOIBM(false);
    setReInviteFalse();
  };
  const handleCloseBulkInvite = () => {
    setOBIBM(false);
    setReInviteFalse();
  };

  const handleSubmitInvite = async (values: any) => {
    try {
      setSubmitApiStatus(API_STATUS.LOADING);
      const response = await backersManager.inviteMember(values);
      toast.success(response?.message);
      setSubmitApiStatus(API_STATUS.SUCCESS);
      PostHogManager.trackEvent(PHE.InviteBackerToSyndicateSuccess);
      handleCloseInvite();
      getBackersData();
    } catch (e: any) {
      toast.error(e);
      PostHogManager.trackEvent(PHE.InviteBackerToSyndicateFailed);
      setSubmitApiStatus(API_STATUS.ERROR);
      handleCloseInvite();
    }
  };

  const handleSubmitReInvite = async (values: any) => {
    try {
      setSubmitApiStatus(API_STATUS.LOADING);
      const response = await backersManager.reInviteMember(
        openInviteBackerModal?.inv_id,
        values?.medium,
        TYPE_OF_COMMUNICATION_KEY[openInviteBackerModal?.onboarding_status],
        openInviteBackerModal?.type_of_investment_profile
      );
      toast.success(response?.message);
      PostHogManager.trackEvent(
        openInviteBackerModal?.onboarding_status === BACKER_ONBOARDING_STATUS.INVITED
          ? PHE.ReInviteBackerInvitedTabSuccess
          : PHE.RemindBackerCASentTabSuccess
      );
      setSubmitApiStatus(API_STATUS.SUCCESS);
      handleCloseInvite();
    } catch (e: any) {
      setSubmitApiStatus(API_STATUS.ERROR);
      PostHogManager.trackEvent(
        openInviteBackerModal?.onboarding_status === BACKER_ONBOARDING_STATUS.INVITED
          ? PHE.ReInviteBackerInvitedTabFailed
          : PHE.RemindBackerCASentTabFailed
      );
      handleCloseInvite();
      toast.error(e);
    }
  };

  const handleSubmitBulkInvite = async (values: any) => {
    try {
      setSubmitApiStatus(API_STATUS.LOADING);
      const response = await backersManager.bulkInviteMember(values);
      toast.success(response?.message);
      setSubmitApiStatus(API_STATUS.SUCCESS);
      PostHogManager.trackEvent(PHE.BulkInviteBackerToSyndicateSuccess);
      handleCloseBulkInvite();
    } catch (e: any) {
      toast.error(e);
      PostHogManager.trackEvent(PHE.BulkInviteBackerToSyndicateFailed);
      handleCloseBulkInvite();
      setSubmitApiStatus(API_STATUS.ERROR);
    }
  };

  const handleSubmitBulkReInvite = async (values: any) => {
    try {
      setSubmitApiStatus(API_STATUS.LOADING);
      const response = await backersManager.bulkReInviteMember(
        selectedRows,
        values?.medium,
        TYPE_OF_COMMUNICATION_KEY[openInviteBackerModal?.onboarding_status]
      );
      toast.success(response?.data);
      PostHogManager.trackEvent(
        openInviteBackerModal?.onboarding_status === BACKER_ONBOARDING_STATUS.INVITED
          ? PHE.BulkReInviteSelectedBackerInvitedTabSuccess
          : PHE.BulkRemindSelectedBackerCASentTabSuccess
      );
      handleCloseBulkInvite();
      setSubmitApiStatus(API_STATUS.SUCCESS);
    } catch (e: any) {
      handleCloseBulkInvite();
      toast.error(e);
      PostHogManager.trackEvent(
        openInviteBackerModal?.onboarding_status === BACKER_ONBOARDING_STATUS.INVITED
          ? PHE.BulkReInviteSelectedBackerInvitedTabFailed
          : PHE.BulkRemindSelectedBackerCASentTabFailed
      );
      setSubmitApiStatus(API_STATUS.ERROR);
    }
  };

  let backersColumns: any = backersColumnsData?.map((item: ColumnObjectType) => {
    switch (item?.field) {
      case 'name':
        item.renderCell = (params: any) => (
          <LinkButton
            title={params?.row?.name}
            onClick={() => {
              PostHogManager.trackEvent(PHE.InvestorDetailsClicksBackersPage);
              navigate(`/backers/${params?.row?.inv_id}`);
            }}
          />
        );

        return item;

      case 'actions':
        item.renderCell = (params: any) => {
          return (
            <ActionContainer>
              {(params?.row?.onboarding_status === BACKER_ONBOARDING_STATUS.INVITED ||
                params?.row?.onboarding_status === BACKER_ONBOARDING_STATUS.CA_SENT) && (
                <LinkButton
                  onClick={() => {
                    PostHogManager.trackEvent(
                      params?.row?.onboarding_status === BACKER_ONBOARDING_STATUS.INVITED
                        ? PHE.ReInviteBackerInvitedTab
                        : PHE.RemindBackerCASentTab
                    );
                    setReInvite(true);
                    setOIBM(params.row);
                  }}
                  title={params?.row?.onboarding_status === BACKER_ONBOARDING_STATUS.INVITED ? 're-invite' : 'remind'}
                ></LinkButton>
              )}
              {/* <LinkButton onClick={() => {}} title="block"></LinkButton> */}
            </ActionContainer>
          );
        };
        return item;
      default:
        return item;
    }
  });

  const CustomToolBarButton = (
    <Box sx={{ marginLeft: 'auto' }}>
      {(selectedStatus === BACKER_ONBOARDING_STATUS.INVITED || selectedStatus === BACKER_ONBOARDING_STATUS.CA_SENT) && (
        <LinkButton
          title={
            selectedStatus === BACKER_ONBOARDING_STATUS.INVITED
              ? 're-invite selected backers'
              : selectedStatus === BACKER_ONBOARDING_STATUS.CA_SENT
              ? 'remind selected backers'
              : ''
          }
          onClick={() => {
            PostHogManager.trackEvent(
              selectedStatus === BACKER_ONBOARDING_STATUS.INVITED
                ? PHE.BulkReInviteSelectedBackerInvitedTab
                : PHE.BulkRemindSelectedBackerCASentTab
            );
            setReInvite(true);
            setOBIBM(true);
          }}
          disabled={!selectedRows?.length}
        ></LinkButton>
      )}
    </Box>
  );

  if (loading === API_STATUS.ERROR) {
    return <NoDataAvailable title="Error" subTitle={'Something went wrong'} image={IconConstant.DATA_NOT_FOUND_ICON} />;
  }

  return (
    <Box sx={{ overflowX: 'hidden' }}>
      {openInviteBackerModal && (
        <InviteBackerModal
          title={`${
            reInvite
              ? openInviteBackerModal?.onboarding_status === BACKER_ONBOARDING_STATUS.INVITED
                ? 'Re-Invite'
                : 'Remind'
              : 'Invite'
          } Backer to Syndicate`}
          openModal={openInviteBackerModal}
          reInvite={reInvite}
          handleClose={handleCloseInvite}
          handleSubmit={reInvite ? handleSubmitReInvite : handleSubmitInvite}
          apiLoading={submitApiStatus === API_STATUS.LOADING}
          testActionType={
            reInvite
              ? openInviteBackerModal?.onboarding_status === BACKER_ONBOARDING_STATUS.INVITED
                ? 'remind'
                : null
              : 'invite'
          }
        />
      )}
      {openBulkInviteBackerModal && (
        <BulkInviteBackerModal
          title={`Bulk ${
            reInvite ? (selectedStatus === BACKER_ONBOARDING_STATUS.INVITED ? 'Re-Invite' : 'Remind') : 'Invite'
          } Backers to Syndicate`}
          openModal={openBulkInviteBackerModal}
          reInvite={reInvite}
          selectedRows={selectedRows}
          handleClose={handleCloseBulkInvite}
          handleSubmit={reInvite ? handleSubmitBulkReInvite : handleSubmitBulkInvite}
          apiLoading={submitApiStatus === API_STATUS.LOADING}
        />
      )}
      <Stack direction="row" justifyContent="space-between">
        <Breadcrumb
          steps={breadcrumbData.steps}
          breadcrumbSeparator={breadcrumbData.breadcrumbSeparator}
          pageHeader={breadcrumbData.pageHeader}
          stepKey={breadcrumbData.stepKey}
        />
        <Box gap={1} display="flex">
          <ActionButton
            onClick={() => {
              PostHogManager.trackEvent(PHE.BulkInviteBackerToSyndicate);
              setOBIBM(true);
            }}
          >
            Bulk Invite Backers
          </ActionButton>
          <ActionButton
            onClick={() => {
              PostHogManager.trackEvent(PHE.InviteBackerToSyndicate);
              setOIBM(true);
            }}
          >
            Invite Backer to Syndicate
          </ActionButton>
        </Box>
      </Stack>
      <Stack direction="row" gap={2} alignItems="center" mb={2} mt={1}>
        <Typography fontSize="14px">Filter By Status:</Typography>
        <FilterChip
          label={BACKER_ONBOARDING_STATUS.ALL}
          icon={<CheckCircleOutline fontSize="small" />}
          active={selectedStatus === BACKER_ONBOARDING_STATUS.ALL}
          onClick={() => {
            setSelectedStatus(BACKER_ONBOARDING_STATUS.ALL);
          }}
        />
        <FilterChip
          label={BACKER_ONBOARDING_STATUS.INVITED}
          icon={<CheckCircleOutline fontSize="small" />}
          active={selectedStatus === BACKER_ONBOARDING_STATUS.INVITED}
          onClick={() => {
            setSelectedStatus(BACKER_ONBOARDING_STATUS.INVITED);
          }}
        />
        <FilterChip
          label={BACKER_ONBOARDING_STATUS.CA_SENT}
          icon={<CheckCircleOutline fontSize="small" />}
          active={selectedStatus === BACKER_ONBOARDING_STATUS.CA_SENT}
          onClick={() => {
            setSelectedStatus(BACKER_ONBOARDING_STATUS.CA_SENT);
          }}
        />
        <FilterChip
          label={BACKER_ONBOARDING_STATUS.CA_ESIGNED}
          icon={<CheckCircleOutline fontSize="small" />}
          active={selectedStatus === BACKER_ONBOARDING_STATUS.CA_ESIGNED}
          onClick={() => {
            setSelectedStatus(BACKER_ONBOARDING_STATUS.CA_ESIGNED);
          }}
        />

        <FilterChip
          label={BACKER_ONBOARDING_STATUS.PROFILE_CREATED}
          icon={<CheckCircleOutline fontSize="small" />}
          active={selectedStatus === BACKER_ONBOARDING_STATUS.PROFILE_CREATED}
          onClick={() => {
            setSelectedStatus(BACKER_ONBOARDING_STATUS.PROFILE_CREATED);
          }}
        />
      </Stack>
      {loading === API_STATUS.LOADING ? (
        <TablePlaceholder rows={5} columns={5} rowHeight="40px" columnHeaderHeight="40px" />
      ) : (
        <LvComplexTable
          rows={data[BACKER_ONBOARDING_STATUS_KEY[selectedStatus]]}
          columns={backersColumns}
          customToolbarButton={CustomToolBarButton}
          leftPinnedColumns={[GRID_CHECKBOX_SELECTION_FIELD, 'name']}
          checkboxSelection={
            selectedStatus === BACKER_ONBOARDING_STATUS.INVITED || selectedStatus === BACKER_ONBOARDING_STATUS.CA_SENT
          }
          onselectionChange={(selectedRows: any) => setSelectedRows(selectedRows)}
          rowHeight={48}
          headerHeight={50}
        />
      )}
    </Box>
  );
}

export default Backers;
