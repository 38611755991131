import { Box, Stack, Tooltip, Typography } from '@mui/material';
import React, { FC } from 'react';
import { CardContainer } from '../../../ui-library/components/card/card-container';
import { CheckCircleOutline, CloudDownloadOutlined, HelpOutlineOutlined } from '@mui/icons-material';
import { SectionHeader } from '../../../ui-library/components/header/section-header';
import { toast } from 'react-toastify';
import { DealStatusEnum, DealStatusEnumLabel } from '../constants/DealsConstant';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import TableLogo from '../../common/components/TableLogo';
import CustomProgressBar from '../../../ui-library/components/progress-bar/CusotmProgressBar';
import DealTypeTooltip from './DealTypeTooltip';
import PostHogManager from '../../../analytics/postHogManager';
import PHE from '../../common/constants/PostHogEvents';

const LiveDeal: FC<any> = ({ startupData }) => {
  const { id } = useParams();

  const handleDownload = (url: string) => {
    try {
      window.open(url, '_blank', 'noreferrer');
    } catch (e: any) {
      toast.error('Error in fetching document');
    }
  };

  const downloadSchemeDoc = () => {
    PostHogManager.trackEvent(PHE.SchemeDocDownloadLiveDealDetail);
    handleDownload(startupData?.scheme_data?.scheme_doc);
  };
  const downloadLeadSchemeDoc = () => {
    PostHogManager.trackEvent(PHE.LeadSchemeDocDownloadLiveDealDetail);
    handleDownload(startupData?.scheme_data?.lead_scheme_doc);
  };

  const cardOptions = [
    {
      label: 'Launched',
      spacing: 3,
      value: startupData?.formated_launched_date ?? '-'
    },
    {
      label: `Backers`,
      spacing: 3,
      value: startupData?.total_backers ?? '-'
    },
    {
      label: 'Scheme Doc',
      spacing: 3,
      renderer: startupData?.scheme_data?.scheme_doc ? (
        <CloudDownloadOutlined sx={{ cursor: 'pointer' }} onClick={downloadSchemeDoc} />
      ) : (
        '-'
      )
    },
    {
      label: 'Lead Scheme Doc',
      spacing: 3,
      renderer: startupData?.scheme_data?.lead_scheme_doc ? (
        <CloudDownloadOutlined sx={{ cursor: 'pointer' }} onClick={downloadLeadSchemeDoc} />
      ) : (
        '-'
      )
    }
  ];

  const cardOptions2 = [
    {
      label: 'Transferred to Startup',
      spacing: 3,
      value: startupData?.formated_lv_total_received ?? '-'
    },
    {
      label: 'Transfer Date',
      spacing: 3,
      value: startupData?.formated_transfer_date ?? '-'
    }
  ];
  return (
    <Box className="card_border text-left" mb={3}>
      <Stack padding="1.875rem" pb={0} direction="row" justifyContent="space-between" alignItems="center">
        <Box gap={2} display="flex" alignItems="center">
          <TableLogo url={startupData?.logo} />
          {!id ? (
            <Link to={`/deals/${startupData?.id}`} style={{ textDecoration: 'none' }}>
              <SectionHeader>{startupData?.name}</SectionHeader>
            </Link>
          ) : (
            <SectionHeader>{startupData?.name}</SectionHeader>
          )}
        </Box>
        <Box display="flex" alignItems="center" gap={0.6}>
          <CheckCircleOutline fontSize="small" />{' '}
          <Typography>
            Status :{' '}
            <Typography variant="heading_04_medium" sx={{ display: 'inline' }}>
              {DealStatusEnumLabel[startupData?.deal_status]}
            </Typography>
          </Typography>
          <Tooltip
            title={<DealTypeTooltip />}
            placement="top-start"
            arrow
            componentsProps={{ tooltip: { sx: { background: '#f2f2f2', color: '#000' } } }}
            sx={{ position: 'relative', width: '10%' }}
          >
            <HelpOutlineOutlined fontSize="small" />
          </Tooltip>
        </Box>
      </Stack>
      <CardContainer options={cardOptions} />
      <Box px={'1.875rem'} mb={'1rem'}>
        <Typography variant="paragraph_03_bold" sx={{ mb: 1 }} textAlign={'left'}>
          Committed:{' '}
          <Typography variant="heading_04_medium" fontWeight={600} sx={{ display: 'inline' }}>
            {startupData?.formated_total_commitment_diy ?? 0}
          </Typography>
        </Typography>

        <CustomProgressBar
          lvRaisedPercentage={startupData?.lv_total_received_percentage?.toFixed(2)}
          receivedPercentage={startupData?.lv_raised_percentage?.toFixed(2)}
        />
        <Stack direction="row" justifyContent="space-between" sx={{ mt: 1 }}>
          <Typography variant="paragraph_03_bold">
            Money Received:{' '}
            <Typography variant="heading_04_medium" fontWeight={600} sx={{ display: 'inline' }}>
              {startupData?.formated_lv_total_received ?? 0}
            </Typography>
          </Typography>
          <Typography variant="paragraph_03_bold">
            Funding Ask:{' '}
            <Typography variant="heading_04_medium" fontWeight={600} sx={{ display: 'inline' }}>
              {startupData?.formated_lv_ask}
            </Typography>
          </Typography>
        </Stack>
      </Box>
      {startupData?.deal_status === DealStatusEnum.FUNDED && <CardContainer options={cardOptions2} />}
    </Box>
  );
};

export default LiveDeal;
