import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';
import MainLoader from '../../../common/loaders/MainLoader';
import { AuthContext } from '../../../../contexts/authContext';

const Logout = () => {
  const { logout } = useAuth0();
  const navigate = useNavigate();
  const { getUserSession } = useContext(AuthContext);
  const { user: { impersonator = null } = {} } = getUserSession();
  const handleSubmit = async () => {
    const redirectUrl = impersonator ? process.env.REACT_APP_ADMIN_PANEL_URL : window.location.origin;
    try {
      await logout({
        logoutParams: {
          returnTo: `${redirectUrl}`
        }
      });
    } catch (error) {
      toast.error('Something went wrong');
    } finally {
      localStorage.clear();
      navigate('/login', { replace: true });
    }
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  return <MainLoader />;
};

export default Logout;
