import React, { FC, useContext, useState } from 'react';
import { CommonModal } from '../../../ui-library/components/modals/common-modal';
import { toast } from 'react-toastify';
import { Stack, Typography } from '@mui/material';
import { ActionButton } from '../../../ui-library/components/buttons/action-button';
import SettingManager from '../manager/SettingManager';
import { AuthContext } from '../../../contexts/authContext';
import { OutlinedButton } from '../../../ui-library/components/buttons/outlined-button';
import { API_STATUS } from '../../common/constants/CommonConstants';
import PostHogManager from '../../../analytics/postHogManager';
import PHE from '../../common/constants/PostHogEvents';

const RemoveAdmin: FC<any> = ({ openModal, handleClose, getAdminData }) => {
  const authContext = useContext(AuthContext);
  const settingManager = new SettingManager(authContext);
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);

  const handleSubmit = async () => {
    try {
      setApiStatus(API_STATUS.LOADING);
      const data = { syndicate_member_id: openModal?.id, name: openModal?.name, email: openModal?.email };
      const response = await settingManager.removeAdminMember(data);
      toast.success('Removed Successfully.');
      PostHogManager.trackEvent(PHE.DeleteAdminSuccess);
      handleClose();
      setApiStatus(API_STATUS.SUCCESS);
      getAdminData && getAdminData();
    } catch (e: any) {
      toast.error(e);
      PostHogManager.trackEvent(PHE.DeleteAdminFailed);
      setApiStatus(API_STATUS.ERROR);
    }
  };

  return (
    <CommonModal isOpen={openModal} handleClose={handleClose} title={`Are you sure you want to remove ?`} hideButtons>
      <Typography variant="heading_04_medium">Selected Admin - {openModal?.email}</Typography>
      <br />
      <br />
      <Stack direction="row" justifyContent="center" gap={2}>
        <OutlinedButton onClick={handleClose}>No</OutlinedButton>
        <ActionButton
          onClick={handleSubmit}
          disabled={apiStatus === API_STATUS.LOADING || apiStatus === API_STATUS.ERROR}
          isLoading={apiStatus === API_STATUS.LOADING}
        >
          Yes
        </ActionButton>
      </Stack>
    </CommonModal>
  );
};

export default RemoveAdmin;
