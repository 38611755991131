import posthog from 'posthog-js';
/* eslint no-console: "error" */
class PostHogManager {
  static hasUserIdentified = false;

  static identifyUser() {
    const syndicateId = localStorage.getItem('syndicate_id');
    const syndicatePermalink = localStorage.getItem('syndicate_permalink');
    const user = localStorage.getItem('user');
    if (!PostHogManager.hasUserIdentified) {
      if (!!user) {
        const identifyUserProperty = { user_id: user.user_id, name: user.name, email: user.email };
        const identifySyndicateProperty = { syndicateId, syndicatePermalink };
        const uniqId = user.user_id;
        posthog.identify(uniqId, identifyUserProperty, identifySyndicateProperty);
      } else {
        posthog.identify('anonymous');
      }
      PostHogManager.hasUserIdentified = true;
    }
  }

  static initiatePostHog() {
    posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
      api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
      autocapture: false,
      loaded() {
        PostHogManager.identifyUser();
      }
    });
  }

  static trackPage() {
    PostHogManager.identifyUser();
    // posthog.capture('Root Page');
  }

  static trackEvent(event) {
    let user = localStorage.getItem('user');
    const id = localStorage.getItem('syndicate_id');
    if (user && id && process.env.REACT_APP_ENV !== 'development') {
      user = JSON.parse(user);
      let userProperties = {
        id,
        email: user?.email,
        name: user?.name
      };
      posthog.identify(id, userProperties);
      posthog.capture(event?.event, { source: event?.source, description: event?.description });
    }
  }
}
export default PostHogManager;
