import { Alert, Box, Stack, Typography } from '@mui/material';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Breadcrumb } from '../../../ui-library/components/navs/breadcrumb';
import { CardContainer } from '../../../ui-library/components/card/card-container';
import { Link } from 'react-router-dom';
import { DropdownButton } from '../../../ui-library/components/buttons/dropdown-button';
import {
  ActionOptions,
  ActionOptionsListCaSent,
  ActionOptionsListInvite
} from '../constants/IndividualBackerConstants';
import { SectionHeader } from '../../../ui-library/components/header/section-header';
import { InfoOutlined } from '@mui/icons-material';
import { LvComplexTable } from '../../../ui-library/components/tables/lv-complex-table';
import TableLogo from '../../common/components/TableLogo';
import { AuthContext } from '../../../contexts/authContext';
import BackersManager from '../manager/BackersManager';
import { toast } from 'react-toastify';
import { LinkButton } from '../../../ui-library/components/buttons/link-button';
import { TablePlaceholder } from '../../../ui-library/components/placeholders/table-placeholder/TablePlaceholder';
import { NoDataAvailable } from '../../../ui-library/components/error/NoDataAvailable';
import BackerDetailCardSkeleton from '../skeletons/BackerDetailCardSkeleton';
import { BACKER_ONBOARDING_STATUS, TYPE_OF_COMMUNICATION_KEY } from '../constants/BackersConstant';
import { validateArray } from '../../common/utils/commonUtils';
import { API_STATUS } from '../../common/constants/CommonConstants';
import { IconConstant } from '../../common/constants/IconConstants';
import { getColor } from '../../../ui-library/colors';
import PostHogManager from '../../../analytics/postHogManager';
import PHE from '../../common/constants/PostHogEvents';

const IndividualBacker: FC = () => {
  const authContext = useContext(AuthContext);
  const backersManager = new BackersManager(authContext);
  const { id } = useParams();
  const navigate = useNavigate();

  const [filters, setFilters] = useState([]);

  const [letChooseEmail, setLetChooseEmail] = useState(true);
  const [letChooseWhatsapp, setLetChooseWhatsapp] = useState(true);

  const [backerInfo, setBackerInfo] = useState<any>(null);
  const [startupList, setStartupList] = useState<any>([]);
  const [apiStatus, setApiStatus] = useState(API_STATUS.LOADING);

  const checkCommunicationRecord = async (investor_id: any, onboarding_status: string) => {
    try {
      const response = await backersManager.checkCommunicationRecord(
        investor_id,
        TYPE_OF_COMMUNICATION_KEY[onboarding_status]
      );
      setLetChooseEmail(response?.let_choose_email);
      setLetChooseWhatsapp(response?.let_choose_whatsapp);
    } catch (e: any) {
      toast.error(e);
    }
  };

  const getBackersData = async () => {
    try {
      const result = await backersManager.getBackerDetail(id);
      if (result?.backer_info) {
        setBackerInfo(result?.backer_info);
        await checkCommunicationRecord(result?.backer_info?.investor_id, result?.backer_info?.onboarding_status);
      }
      if (validateArray(result?.backer_startup_list)) {
        setStartupList(result?.backer_startup_list);
      }
      setApiStatus(API_STATUS.SUCCESS);
    } catch (error: any) {
      toast.error(error);
      setApiStatus(API_STATUS.ERROR);
    }
  };

  useEffect(() => {
    getBackersData();
  }, []);

  const breadcrumbData = {
    breadcrumbSeparator: true,
    stepKey: 2,
    steps: [
      {
        title: 'DASHBOARD - BACKERS',
        titleDisplay: true
      },
      {
        title: backerInfo?.name,
        titleDisplay: true
      }
    ],
    pageHeader: {
      header: backerInfo?.name,
      navigationButton: true,
      navigationFunction: () => navigate(-1)
    }
  };

  const cardOptions: any = [
    {
      label: 'Investor Name',
      spacing: 3,
      value: backerInfo?.name ?? '-'
    },
    {
      label: 'Email',
      spacing: 6,
      value: backerInfo?.email ?? '-'
    },
    {
      label: 'Mobile',
      spacing: 3,
      value: backerInfo?.phone_no ?? '-'
    },

    {
      label: 'AIF Onboarding Status',
      spacing: 3,
      value: backerInfo?.onboarding_status ?? '-'
    },
    {
      label: 'Invited to Syndicate',
      spacing: 3,
      value: backerInfo?.backing_date ?? '-'
    },
    {
      label: 'AIF Onboarded Date',
      spacing: 3,
      value: backerInfo?.onboarding_date ?? '-'
    },
    {
      label: 'Joined as Backer',
      spacing: 3,
      value: backerInfo?.backing_date ?? '-'
    },
    {
      label: '#deals',
      spacing: 3,
      value: backerInfo?.deals_participated ?? '-'
    },
    {
      label: 'Total transferred',
      spacing: 3,
      value: backerInfo?.formated_amount_transferred ?? '-'
    },
    {
      label: 'Unallocated Funds',
      spacing: 3,
      value: backerInfo?.formated_unallocated_amount ?? '-'
    },
    {
      label: 'Access Public Deals?',
      spacing: 3,
      value: backerInfo?.access ? 'Yes' : 'No',
      helpText: 'If Yes, backer will have both Private deal and Public deal access.'
    },

    {
      label: 'Last Invited to Syndicate',
      spacing: 3,
      value: backerInfo?.last_invited_to_syndicate ?? '-'
    }
  ];

  if (backerInfo?.linkedin_url) {
    cardOptions?.splice(3, 0, {
      label: 'Go To',
      spacing: 3,
      renderer: (
        <Typography
          to={backerInfo?.linkedin_url}
          className="underline"
          target="_blank"
          component={Link}
          variant="heading_04_medium"
          fontWeight={600}
          color={getColor('action_button', 300)}
        >
          Linkedin Profile
        </Typography>
      )
    });
  }

  const columnsData = [
    {
      field: 'logo',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 50,
      renderCell: (params: any) => <TableLogo id={params?.row?.id} url={params?.row?.logo} />
    },
    {
      field: 'name',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      headerName: 'STARTUP NAME',
      renderCell: (params: any) => (
        <LinkButton
          title={params?.row?.name}
          onClick={() => navigate(`/deals/${params?.row?.id}`, { state: params?.row })}
        />
      )
    },
    {
      field: 'invited_date',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      headerName: 'INVITED TO DEAL',
      type: 'date',
      renderCell: (params: any) => params?.row?.formated_invited_date,
      valueGetter: (params: any) => params?.row?.invited_date
    },
    {
      field: 'commited_date',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      headerName: 'COMMITED DATE',
      type: 'date',
      renderCell: (params: any) => params?.row?.formated_commited_date,
      valueGetter: (params: any) => params?.row?.commited_date
    },
    {
      field: 'commit_amount',
      headerAlign: 'right',
      align: 'right',
      flex: 1,
      minWidth: 170,
      headerName: 'COMMITED AMOUNT',
      type: 'number',
      renderCell: (params: any) => params?.row?.formated_commit_amount
    },
    {
      field: 'drawdown_sent_date',
      headerAlign: 'right',
      align: 'right',
      flex: 1,
      minWidth: 150,
      headerName: 'DRAWDOWN SENT',
      type: 'date',
      renderCell: (params: any) => params?.row?.formated_drawdown_sent_date,
      valueGetter: (params: any) => params?.row?.drawdown_sent_date
    },
    {
      field: 'money_received_date',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      type: 'date',
      headerName: 'TRANSFER TO AIF',
      renderCell: (params: any) => params?.row?.formated_money_received_date,
      valueGetter: (params: any) => params?.row?.money_received_date
    }
  ];

  const handleSubmitReInvite = async (medium: string) => {
    try {
      PostHogManager.trackEvent(
        backerInfo?.onboarding_status === BACKER_ONBOARDING_STATUS.INVITED
          ? PHE.ReInviteBackerOfInvitedStatusBackerDetail
          : PHE.RemindBackerOfCASentStatusBackerDetail
      );
      const response = await backersManager.reInviteMember(
        backerInfo?.investor_id,
        medium,
        TYPE_OF_COMMUNICATION_KEY[backerInfo?.onboarding_status]
      );
      PostHogManager.trackEvent(
        backerInfo?.onboarding_status === BACKER_ONBOARDING_STATUS.INVITED
          ? PHE.ReInviteBackerOfInvitedStatusBackerDetailSuccess
          : PHE.RemindBackerOfCASentStatusBackerDetailSuccess
      );
      toast.success(response?.message);
    } catch (e: any) {
      PostHogManager.trackEvent(
        backerInfo?.onboarding_status === BACKER_ONBOARDING_STATUS.INVITED
          ? PHE.ReInviteBackerOfInvitedStatusBackerDetailFailed
          : PHE.RemindBackerOfCASentStatusBackerDetailFailed
      );
      toast.error(e);
    }
  };

  const handleActionClick = async (value: string) => {
    switch (value) {
      case ActionOptions.SEND_REINVITE_EMAIL:
        await handleSubmitReInvite('email');
        return;
      case ActionOptions.SEND_REMIND_EMAIL:
        await handleSubmitReInvite('email');
        return;
      case ActionOptions.SEND_REINVITE_WHATSAPP:
        await handleSubmitReInvite('whatsapp');
        return;
      case ActionOptions.SEND_REMIND_WHATSAPP:
        await handleSubmitReInvite('whatsapp');
        return;
      case 'default':
        return;
    }
  };

  if (apiStatus === API_STATUS.ERROR) {
    return <NoDataAvailable title="Error" subTitle={'Something went wrong'} image={IconConstant.DATA_NOT_FOUND_ICON} />;
  }

  return (
    <Box sx={{ overflowX: 'hidden' }}>
      <Stack direction="row" justifyContent="space-between">
        <Breadcrumb
          steps={breadcrumbData.steps}
          breadcrumbSeparator={breadcrumbData.breadcrumbSeparator}
          pageHeader={breadcrumbData.pageHeader}
          stepKey={breadcrumbData.stepKey}
        />
        {(backerInfo?.onboarding_status === BACKER_ONBOARDING_STATUS.INVITED ||
          backerInfo?.onboarding_status === BACKER_ONBOARDING_STATUS.CA_SENT) && (
          <DropdownButton
            options={
              backerInfo?.onboarding_status === BACKER_ONBOARDING_STATUS.INVITED
                ? ActionOptionsListInvite(letChooseEmail, letChooseWhatsapp)
                : ActionOptionsListCaSent(letChooseEmail, letChooseWhatsapp)
            }
            onOptionClick={handleActionClick}
            buttonText="Actions"
          />
        )}
      </Stack>

      {apiStatus === API_STATUS.LOADING ? (
        <BackerDetailCardSkeleton />
      ) : (
        <CardContainer options={cardOptions} customClass="card_border text-left" />
      )}
      <Box mt={2} mb={2}>
        <SectionHeader customStyles={{ textAlign: 'start' }}>Deals</SectionHeader>
        <Alert
          severity="info"
          sx={{ padding: '0 10px', width: 'fit-content' }}
          icon={<InfoOutlined fontSize="small" />}
        >
          Note: Only committed investors details are shown here. For live deals, visit the respective deal page itself.
        </Alert>
      </Box>

      {apiStatus === API_STATUS.LOADING ? (
        <TablePlaceholder rows={6} columns={6} rowHeight="40px" columnHeaderHeight="40px" />
      ) : (
        <LvComplexTable
          rows={startupList}
          columns={columnsData}
          leftPinnedColumns={['logo', 'startup_name']}
          activeFilters={filters}
          onFilterChange={(filters: any) => setFilters(filters)}
          rowHeight={48}
          headerHeight={50}
        />
      )}
    </Box>
  );
};

export default IndividualBacker;
