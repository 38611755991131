import { Box, FormHelperText, Stack } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Breadcrumb } from '../../../ui-library/components/navs/breadcrumb';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { InputField } from '../../../ui-library/components/form-elements/CustomTextField';
import { RadioButtonSelect } from '../../../ui-library/components/form-elements/RadioButtonSelect';
import { ActionButton } from '../../../ui-library/components/buttons/action-button';
import { OutlinedButton } from '../../../ui-library/components/buttons/outlined-button';
import { toast } from 'react-toastify';
import { AuthContext } from '../../../contexts/authContext';
import SettingManager from '../manager/SettingManager';
import * as Yup from 'yup';
import AutocompleteTags from '../../../ui-library/components/tags/autoCompleteTag';
import { FormPlaceholder } from '../../../ui-library/components/placeholders/form-placeholder/FormPlaceholder';
import { profileFormPlaceholderConfig } from '../../deals/constants/LaunchDealConstant';
import { validateArray } from '../../common/utils/commonUtils';
import { API_STATUS } from '../../common/constants/CommonConstants';
import PostHogManager from '../../../analytics/postHogManager';
import PHE from '../../common/constants/PostHogEvents';

const EditProfile = () => {
  const authContext = useContext(AuthContext);
  const settingManager = new SettingManager(authContext);
  const navigate = useNavigate();

  const [syndicateDetail, setSyndicateDetail] = useState<any>(null);
  const [primaryAdmin, setPrimaryAdmin] = useState<any>(null);

  const [apiStatus, setApiStatus] = useState(API_STATUS.LOADING);
  const [submitting, setSubmitting] = useState(API_STATUS.SUCCESS);
  const [initialValues, setInitialValues] = useState<any>({
    syndicateName: '',
    primaryAdminName: '',
    primaryAdminEmail: '',
    accessCheck: 'no',
    ccEmails: []
  });

  const validationSchema = Yup.object({
    syndicateName: Yup.string().required('Syndicate Name is required'),
    primaryAdminName: Yup.string().required('Primary Admin Name is required'),
    primaryAdminEmail: Yup.string().email('Invalid email format').required('Primary Admin Email is required'),
    accessCheck: Yup.string().required('Access Check is required'),
    ccEmails: Yup.array().test('ccEmails', 'All emails should be valid', function (value) {
      if (value && value.length > 0) {
        return value.every((email: string) => Yup.string().email().isValidSync(email));
      }
      return true;
    })
  });

  const getAdminData = async () => {
    try {
      const response = await settingManager.fetchSyndicateAdmins();
      if (validateArray(response?.result)) {
        const primaryAdmin = response?.result?.find((item: any) => item?.primary_owner);
        if (primaryAdmin) setPrimaryAdmin(primaryAdmin);
      }
    } catch (error: any) {
      toast.error(error);
    }
  };
  const checkBackerAccess = async () => {
    try {
      const response = await settingManager.checkBackerAccess();
      setSyndicateDetail(response);
    } catch (e: any) {
      toast.error(e);
    }
  };

  const getData = async () => {
    try {
      setApiStatus(API_STATUS.LOADING);
      const res = await Promise.all([getAdminData(), checkBackerAccess()]);
      setApiStatus(API_STATUS.SUCCESS);
    } catch (e: any) {
      setApiStatus(API_STATUS.ERROR);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (syndicateDetail) {
      setInitialValues({
        syndicateName: syndicateDetail?.syndicate_name || '',
        primaryAdminName: primaryAdmin?.name || '',
        primaryAdminEmail: primaryAdmin?.email || '',
        accessCheck: syndicateDetail?.access ? 'yes' : 'no',
        ccEmails: syndicateDetail?.cc_email ?? []
      });
    }
  }, [syndicateDetail, primaryAdmin]);

  const breadcrumbData = {
    breadcrumbSeparator: true,
    stepKey: 2,
    steps: [
      {
        title: 'DASHBOARD - SETTINGS',
        titleDisplay: true
      },
      {
        title: 'Edit Profile',
        titleDisplay: true
      }
    ],
    pageHeader: {
      header: 'Edit Profile',
      navigationButton: true,
      navigationFunction: () => navigate(-1)
    }
  };

  const handleCancel = () => navigate('/settings');

  const handleSubmit = async (values: any) => {
    try {
      setSubmitting(API_STATUS.LOADING);
      await settingManager.editSyndicateDetail(values);
      setSubmitting(API_STATUS.SUCCESS);
      PostHogManager.trackEvent(PHE.EditProfileSuccess);
      toast.success('Profile updated successfully');
      navigate('/settings');
    } catch (e: any) {
      PostHogManager.trackEvent(PHE.EditProfileFailed);
      toast.error(e.message || 'An error occurred while updating the profile');
      setSubmitting(API_STATUS.ERROR);
    }
  };

  return (
    <Box sx={{ overflowX: 'hidden' }} width="60%" textAlign="left">
      <Breadcrumb
        steps={breadcrumbData.steps}
        breadcrumbSeparator={breadcrumbData.breadcrumbSeparator}
        pageHeader={breadcrumbData.pageHeader}
        stepKey={breadcrumbData.stepKey}
      />
      {apiStatus === API_STATUS.LOADING ? (
        <FormPlaceholder elementsConfig={profileFormPlaceholderConfig} />
      ) : (
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, touched, errors, handleSubmit, isValid, dirty, setFieldValue, setFieldTouched }: any) => {
            return (
              <Stack gap={2}>
                <InputField
                  fieldName="syndicateName"
                  id="syndicateName"
                  label="Syndicate Name"
                  value={values?.syndicateName}
                  error={!!errors?.syndicateName}
                  required
                />
                <InputField
                  fieldName="primaryAdminName"
                  id="primaryAdminName"
                  label="Primary Admin Name"
                  value={values?.primaryAdminName}
                  error={!!errors?.primaryAdminName}
                  required
                />
                <InputField
                  fieldName="primaryAdminEmail"
                  id="primaryAdminEmail"
                  label="Primary Admin Email"
                  type="email"
                  value={values?.primaryAdminEmail}
                  error={!!errors?.primaryAdminEmail}
                  required
                  disabled
                />
                <RadioButtonSelect
                  fieldName="accessCheck"
                  id="accessCheck"
                  formLabel="Do you want to allow backers to access public deals on LetsVenture Platform?"
                  required
                  value={values?.accessCheck}
                  error={!!errors?.accessCheck}
                  options={[
                    { name: 'Yes', value: 'yes' },
                    { name: 'No', value: 'no' }
                  ]}
                />

                <AutocompleteTags
                  // id="ccEmails"
                  // fieldName="ccEmails"
                  // name="ccEmails"
                  defaultValue={values?.ccEmails}
                  dropdownOptions={values?.ccEmails}
                  formikValues={values}
                  tagsKey="ccEmails"
                  label="CC all emails from LetsVenture platform to these email ids"
                  onChange={(value: any) => {
                    setFieldValue('ccEmails', value);
                    setFieldTouched('ccEmails', true);
                  }}
                />
                {errors?.ccEmails && touched?.ccEmails && (
                  <FormHelperText error={true}>{errors?.ccEmails}</FormHelperText>
                )}

                <Stack direction="row" gap={1} justifyContent="end">
                  <OutlinedButton onClick={handleCancel}>Cancel</OutlinedButton>
                  <ActionButton
                    disabled={!isValid || submitting === API_STATUS.LOADING || submitting === API_STATUS.ERROR}
                    isLoading={submitting === API_STATUS.LOADING}
                    onClick={handleSubmit}
                  >
                    Save
                  </ActionButton>
                </Stack>
              </Stack>
            );
          }}
        </Formik>
      )}
    </Box>
  );
};

export default EditProfile;
