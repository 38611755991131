import { Box, Stack } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Breadcrumb } from '../../../ui-library/components/navs/breadcrumb';
import { LvComplexTable } from '../../../ui-library/components/tables/lv-complex-table';
import { LinkButton } from '../../../ui-library/components/buttons/link-button';
import SettingCard from './SettingCard';
import { ActionButton } from '../../../ui-library/components/buttons/action-button';
import { SectionHeader } from '../../../ui-library/components/header/section-header';
import { useNavigate } from 'react-router-dom';
import { breadcrumbData } from '../constants/SettingConstant';
import { toast } from 'react-toastify';
import SettingManager from '../manager/SettingManager';
import { AuthContext } from '../../../contexts/authContext';
import AddAdmin from '../modals/AddAdmin';
import { TablePlaceholder } from '../../../ui-library/components/placeholders/table-placeholder/TablePlaceholder';
import { NoDataAvailable } from '../../../ui-library/components/error/NoDataAvailable';
import RemoveAdmin from '../modals/RemoveAdmin';
import SyndicateDetailSkeleton from '../skeletons/SyndicateDetailSkeleton';
import { API_STATUS } from '../../common/constants/CommonConstants';
import { IconConstant } from '../../common/constants/IconConstants';
import { validateArray } from '../../common/utils/commonUtils';
import PostHogManager from '../../../analytics/postHogManager';
import PHE from '../../common/constants/PostHogEvents';

const Settings = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const settingManager = new SettingManager(authContext);

  const [apiStatus, setApiStatus] = useState(API_STATUS.LOADING);
  const [adminList, setAdminList] = useState([]);
  const [syndicateDetail, setSyndicateDetail] = useState(null);
  const [primaryAdmin, setPrimaryAdmin] = useState(null);
  const [openAddAdminModal, setOpenAddAdminModal] = useState(false);
  const [openDeleteAdminModal, setOpenDeleteAdminModal] = useState(null);

  const getAdminData = async () => {
    try {
      const response = await settingManager.fetchSyndicateAdmins();
      if (validateArray(response?.result)) {
        setAdminList(response?.result);
        const primaryAdmin = response?.result?.find((item: any) => item?.primary_owner);
        if (primaryAdmin) setPrimaryAdmin(primaryAdmin);
      }
    } catch (error: any) {
      toast.error(error);
    }
  };
  const checkBackerAccess = async () => {
    try {
      const response = await settingManager.checkBackerAccess();
      setSyndicateDetail(response);
    } catch (e: any) {
      toast.error(e);
    }
  };

  const getData = async () => {
    try {
      const res = await Promise.all([getAdminData(), checkBackerAccess()]);
      setApiStatus(API_STATUS.SUCCESS);
    } catch (e: any) {
      setApiStatus(API_STATUS.ERROR);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const columnsData = [
    {
      field: 'name',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 250,
      headerName: 'NAME'
    },
    {
      field: 'email',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 300,
      headerName: 'EMAIL'
    },
    {
      field: 'mobile',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 150,
      headerName: 'MOBILE'
    },
    {
      field: 'onboarding_status',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      headerName: 'STATUS'
    },
    {
      field: 'active_from',
      headerAlign: 'right',
      align: 'right',
      flex: 1,
      minWidth: 150,
      headerName: 'ADMIN SINCE',
      type: 'date',
      valueGetter: (params: any) => params?.row?.active_from
    },
    {
      field: 'actions',
      headerAlign: 'right',
      align: 'right',
      flex: 1,
      minWidth: 150,
      sortable: false,
      headerName: 'ACTIONS',
      renderCell: (params: any) =>
        adminList?.length > 1 &&
        params.row.can_remove &&
        !params.row.primary_owner && (
          <LinkButton
            title="delete"
            onClick={() => {
              PostHogManager.trackEvent(PHE.DeleteAdminClicked);
              setOpenDeleteAdminModal(params?.row);
            }}
          />
        )
    }
  ];

  const navigateToEditProfile = () => {
    PostHogManager.trackEvent(PHE.EditProfileClicked);
    navigate('/settings/edit-profile');
  };
  const closeModal = () => setOpenAddAdminModal(false);
  const openModal = () => {
    PostHogManager.trackEvent(PHE.AddAdminClicked);
    setOpenAddAdminModal(true);
  };
  const closeDeleteAdminModal = () => setOpenDeleteAdminModal(null);

  if (apiStatus === API_STATUS.ERROR) {
    return <NoDataAvailable title="Error" subTitle={'Something went wrong'} image={IconConstant.DATA_NOT_FOUND_ICON} />;
  }

  return (
    <Box sx={{ overflowX: 'hidden' }}>
      <Stack direction="row" justifyContent="space-between">
        <Breadcrumb
          steps={breadcrumbData.steps}
          breadcrumbSeparator={breadcrumbData.breadcrumbSeparator}
          pageHeader={breadcrumbData.pageHeader}
          stepKey={breadcrumbData.stepKey}
        />
        <ActionButton onClick={navigateToEditProfile}>Edit Profile</ActionButton>
      </Stack>
      {apiStatus === API_STATUS.LOADING ? (
        <SyndicateDetailSkeleton />
      ) : (
        <SettingCard syndicateDetail={syndicateDetail} primaryAdmin={primaryAdmin} />
      )}
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1}>
        <SectionHeader>Admins</SectionHeader>

        <ActionButton onClick={openModal}>Add Admin</ActionButton>
        <AddAdmin openModal={openAddAdminModal} handleClose={closeModal} getAdminData={getAdminData} />
        <RemoveAdmin openModal={openDeleteAdminModal} handleClose={closeDeleteAdminModal} getAdminData={getAdminData} />
      </Stack>
      {apiStatus === API_STATUS.LOADING ? (
        <TablePlaceholder rows={6} columns={6} rowHeight="40px" columnHeaderHeight="40px" />
      ) : (
        <LvComplexTable rows={adminList} columns={columnsData} rowHeight={48} headerHeight={50} />
      )}
    </Box>
  );
};

export default Settings;
