const PHE = {
  LaunchNewDealDashboard: {
    event: 'Launch New Deal From Dashboard',
    source: 'Dashboard page',
    description: 'Launch new deal clicked on dashboard page'
  },
  LaunchNewDealDeals: {
    event: 'Launch New Deal From Deals',
    source: 'Deals page',
    description: 'Launch new deal clicked on deals page'
  },
  InvestorDetailsClicksBackersPage: {
    event: 'Investor Details Clicks - Backers Page',
    source: 'Backers page',
    description: "Whenever the user clicks on any investor name in 'Backers List Page"
  },
  InviteBackerToSyndicate: {
    event: 'Invite Backer To Syndicate',
    source: 'Backers page',
    description: 'Invite backer to syndicate clicked on backers page'
  },
  InviteBackerToSyndicateSuccess: {
    event: 'Invite Backer To Syndicate - Success',
    source: 'Backers page',
    description: 'Bulk Invite backer to syndicate clicked on backers page and Failed'
  },
  InviteBackerToSyndicateFailed: {
    event: 'Invite Backer To Syndicate - Failed',
    source: 'Backers page',
    description: 'Invite backer to syndicate clicked on backers page and Failed'
  },
  BulkInviteBackerToSyndicate: {
    event: 'Bulk Invite Backer To Syndicate',
    source: 'Backers page',
    description: 'Bulk Invite backer to syndicate clicked on backers page'
  },
  BulkInviteBackerToSyndicateSuccess: {
    event: 'Bulk Invite Backer To Syndicate - Success',
    source: 'Backers page',
    description: 'Bulk Invite backer to syndicate clicked on backers page and Successful'
  },
  BulkInviteBackerToSyndicateFailed: {
    event: 'Bulk Invite Backer To Syndicate - Failed',
    source: 'Backers page',
    description: 'Bulk Invite backer to syndicate clicked on backers page and Failed'
  },
  ReInviteBackerInvitedTab: {
    event: 'ReInvite Backer - Invited Tab',
    source: 'Backers page - Invited tab',
    description: '"ReInvite" clicked on backer table action - invited tab'
  },
  ReInviteBackerInvitedTabSuccess: {
    event: 'ReInvite Backer - Invited Tab - Success',
    source: 'Backers page - Invited tab',
    description: '"ReInvite" clicked on backer table action - invited tab and Successful'
  },
  ReInviteBackerInvitedTabFailed: {
    event: 'ReInvite Backer - Invited Tab - Failed',
    source: 'Backers page - Invited tab',
    description: '"ReInvite" clicked on backer table action - invited tab and Failed'
  },
  BulkReInviteSelectedBackerInvitedTab: {
    event: 'Bulk ReInvite Selected Backer - Invited Tab',
    source: 'Backers page - Invited tab',
    description: 'Bulk "ReInvite selected backer" clicked on backer table action - invited tab'
  },
  BulkReInviteSelectedBackerInvitedTabSuccess: {
    event: 'Bulk ReInvite Selected Backer - Invited Tab - Success',
    source: 'Backers page - Invited tab',
    description: 'Bulk "ReInvite selected backer" clicked on backer table action - invited tab and Successful'
  },
  BulkReInviteSelectedBackerInvitedTabFailed: {
    event: 'Bulk ReInvite Selected Backer - Invited Tab - Failed',
    source: 'Backers page - Invited tab',
    description: 'Bulk "ReInvite selected backer" clicked on backer table action - invited tab and Failed'
  },
  RemindBackerCASentTab: {
    event: 'Remind Backer - CA Sent Tab',
    source: 'Backers page - Ca sent tab',
    description: '"Remind" clicked on backer table action - ca sent tab'
  },
  RemindBackerCASentTabSuccess: {
    event: 'Remind Backer - CA Sent Tab - Success',
    source: 'Backers page - Ca sent tab',
    description: '"Remind" clicked on backer table action - ca sent tab and Successful'
  },
  RemindBackerCASentTabFailed: {
    event: 'Remind Backer - CA Sent Tab - Failed',
    source: 'Backers page - Ca sent tab',
    description: '"Remind" clicked on backer table action - ca sent tab and Failed'
  },
  BulkRemindSelectedBackerCASentTab: {
    event: 'Bulk Remind Selected Backer - CA Sent Tab',
    source: 'Backers page - Ca sent tab',
    description: 'Bulk "Remind selected backer" clicked on backer table action - ca sent tab'
  },
  BulkRemindSelectedBackerCASentTabSuccess: {
    event: 'Bulk Remind Selected Backer - CA Sent Tab - Success',
    source: 'Backers page - Ca sent tab',
    description: 'Bulk "Remind selected backer" clicked on backer table action - ca sent tab and Successful'
  },
  BulkRemindSelectedBackerCASentTabFailed: {
    event: 'Bulk Remind Selected Backer - CA Sent Tab - Failed',
    source: 'Backers page - Ca sent tab',
    description: 'Bulk "Remind selected backer" clicked on backer table action - ca sent tab and Failed'
  },
  ReInviteBackerOfInvitedStatusBackerDetail: {
    event: 'ReInvite Backer Of Invited Status - Backer Detail',
    source: 'Backer detail page',
    description: '"ReInvite" clicked on backer detail page action - invited status'
  },
  ReInviteBackerOfInvitedStatusBackerDetailSuccess: {
    event: 'ReInvite Backer Of Invited Status - Backer Detail - Success',
    source: 'Backer detail page',
    description: '"ReInvite" clicked on backer detail page action - invited status and Successful'
  },
  ReInviteBackerOfInvitedStatusBackerDetailFailed: {
    event: 'ReInvite Backer Of Invited Status - Backer Detail - Failed',
    source: 'Backer detail page',
    description: '"ReInvite" clicked on backer detail page action - invited status and Failed'
  },
  RemindBackerOfCASentStatusBackerDetail: {
    event: 'Remind Backer Of CA Sent Status - Backer Detail',
    source: 'Backer detail page',
    description: '"Remind" clicked on backer detail page action - ca sent status'
  },
  RemindBackerOfCASentStatusBackerDetailSuccess: {
    event: 'Remind Backer Of CA Sent Status - Backer Detail - Success',
    source: 'Backer detail page',
    description: '"Remind" clicked on backer detail page action - ca sent status and Successful'
  },
  RemindBackerOfCASentStatusBackerDetailFailed: {
    event: 'Remind Backer Of CA Sent Status - Backer Detail - Failed',
    source: 'Backer detail page',
    description: '"Remind" clicked on backer detail page action - ca sent status and Failed'
  },
  ViewStartupDetailDealDetail: {
    event: 'View Startup Detail - Deal Detail',
    source: 'Deal detail page',
    description: '"View Startup Detail" clicked on deal detail page actions'
  },
  ShareDealURLDealDetail: {
    event: 'Share Deal URL - Deal Detail',
    source: 'Deal detail page',
    description: '"Share Deal URL" clicked on deal detail page actions'
  },
  DealURLCopiedDealDetail: {
    event: 'Deal URL copied - Deal Detail',
    source: 'Deal detail page',
    description: '"Share Deal URL" clicked on deal detail page actions and Url copied successfully'
  },
  SchemeDocDownloadLiveDealDetail: {
    event: 'Scheme Doc Download - Live Deal Detail',
    source: 'Deal detail page',
    description: '"Scheme Doc" clicked on live deal detail page card actions'
  },
  LeadSchemeDocDownloadLiveDealDetail: {
    event: 'Lead Scheme Doc Download - Live Deal Detail',
    source: 'Deal detail page',
    description: '"Lead Scheme Doc" clicked on live deal detail page card actions'
  },
  ViewCommunicationLogLiveDealDetail: {
    event: 'View Communication Log - Live Deal Detail',
    source: 'Deal detail page',
    description: '"View Communication Log" clicked on live deal detail page table actions'
  },
  InvestorDetailsClicksBackersTableLiveDealDetailPage: {
    event: 'Investor Details Clicks - Backers Table - Live Deal Detail Page',
    source: 'Deal detail page',
    description: '"Investor Name" clicked on live deal detail page table actions for investor details'
  },
  RemindToCommitBackersTableInvitedTab: {
    event: 'Remind To Commit - Backers Table - Deal Detail - Invited Tab',
    source: 'Deal detail page',
    description: '"Remind" clicked on live deal detail page table actions for backer in invited tab'
  },
  RemindToCommitBackersTableDealDetailInvitedTabSuccess: {
    event: 'Remind To Commit - Backers Table - Deal Detail - Invited Tab - Success',
    source: 'Deal detail page',
    description: '"Remind" clicked on live deal detail page table actions for backer in invited taband Successful'
  },
  RemindToCommitBackersTableDealDetailInvitedTabFailed: {
    event: 'Remind To Commit - Backers Table - Deal Detail - Invited Tab - Failed',
    source: 'Deal detail page',
    description: '"Remind" clicked on live deal detail page table actions for backer in invited tab and Failed'
  },
  RemindToCommitBackersTableDealDetailViewedTab: {
    event: 'Remind To Commit - Backers Table - Deal Detail - Viewed Tab',
    source: 'Deal detail page',
    description: '"Remind" clicked on live deal detail page table actions for backer in viewed tab'
  },
  RemindToCommitBackersTableDealDetailViewedTabSuccess: {
    event: 'Remind To Commit - Backers Table - Deal Detail - Viewed Tab - Success',
    source: 'Deal detail page',
    description: '"Remind" clicked on live deal detail page table actions for backer in viewed tab and Successful'
  },
  RemindToCommitBackersTableDealDetailViewedTabFailed: {
    event: 'Remind To Commit - Backers Table - Deal Detail - Viewed Tab - Failed',
    source: 'Deal detail page',
    description: '"Remind" clicked on live deal detail page table actions for backer in viewed tab and Failed'
  },
  RemindToCommitBackersTableDealDetailCommitedTab: {
    event: 'Remind To Commit - Backers Table - Deal Detail - Commited Tab',
    source: 'Deal detail page',
    description: '"Remind" clicked on live deal detail page table actions for backer in commited tab'
  },
  RemindToCommitBackersTableDealDetailCommitedTabSuccess: {
    event: 'Remind To Commit - Backers Table - Deal Detail - Commited Tab - Success',
    source: 'Deal detail page',
    description: '"Remind" clicked on live deal detail page table actions for backer in commited tab and Successful'
  },
  RemindToCommitBackersTableDealDetailCommitedTabFailed: {
    event: 'Remind To Commit - Backers Table - Deal Detail - Commited Tab - Failed',
    source: 'Deal detail page',
    description: '"Remind" clicked on live deal detail page table actions for backer in commited tab and Failed'
  },
  SendDrawdownBackersTableDealDetailCommitedTab: {
    event: 'Send Drawdown - Backers Table - Deal Detail - Commited Tab',
    source: 'Deal detail page',
    description: '"Send Drawdown" clicked on live deal detail page table actions for backer in commited tab '
  },
  SendDrawdownBackersTableDealDetailCommitedTabSuccess: {
    event: 'Send Drawdown - Backers Table - Deal Detail - Commited Tab - Success',
    source: 'Deal detail page',
    description:
      '"Send Drawdown" clicked on live deal detail page table actions for backer in commited tab and Successful'
  },
  SendDrawdownBackersTableDealDetailCommitedTabFailed: {
    event: 'Send Drawdown - Backers Table - Deal Detail - Commited Tab - Failed',
    source: 'Deal detail page',
    description: '"Send Drawdown" clicked on live deal detail page table actions for backer in commited tab and Failed'
  },
  BulkRemindToCommitSelectedBackersTableInvitedTab: {
    event: 'Bulk Remind To Commit - Selected Backers Table - Deal Detail - Invited Tab',
    source: 'Deal detail page',
    description:
      '"Remind Selected Backers" clicked on live deal detail page table actions for selected backers in invited tab'
  },
  BulkRemindToCommitSelectedBackersTableDealDetailInvitedTabSuccess: {
    event: 'Bulk Remind To Commit - Selected Backers Table - Deal Detail - Invited Tab - Success',
    source: 'Deal detail page',
    description:
      '"Remind Selected Backers" clicked on live deal detail page table actions for selected backers in invited taband Successful'
  },
  BulkRemindToCommitSelectedBackersTableDealDetailInvitedTabFailed: {
    event: 'Bulk Remind To Commit - Selected Backers Table - Deal Detail - Invited Tab - Failed',
    source: 'Deal detail page',
    description:
      '"Remind Selected Backers" clicked on live deal detail page table actions for selected backers in invited tab and Failed'
  },
  BulkRemindToCommitSelectedBackersTableDealDetailViewedTab: {
    event: 'Bulk Remind To Commit - Selected Backers Table - Deal Detail - Viewed Tab',
    source: 'Deal detail page',
    description:
      '"Remind Selected Backers" clicked on live deal detail page table actions for selected backers in viewed tab'
  },
  BulkRemindToCommitSelectedBackersTableDealDetailViewedTabSuccess: {
    event: 'Bulk Remind To Commit - Selected Backers Table - Deal Detail - Viewed Tab - Success',
    source: 'Deal detail page',
    description:
      '"Remind Selected Backers" clicked on live deal detail page table actions for selected backers in viewed tab and Successful'
  },
  BulkRemindToCommitSelectedBackersTableDealDetailViewedTabFailed: {
    event: 'Bulk Remind To Commit - Selected Backers Table - Deal Detail - Viewed Tab - Failed',
    source: 'Deal detail page',
    description:
      '"Remind Selected Backers" clicked on live deal detail page table actions for selected backers in viewed tab and Failed'
  },
  BulkRemindToCommitSelectedBackersTableDealDetailCommitedTab: {
    event: 'Bulk Remind To Commit - Selected Backers Table - Deal Detail - Commited Tab',
    source: 'Deal detail page',
    description:
      '"Remind Selected Backers" clicked on live deal detail page table actions for selected backers in commited tab'
  },
  BulkRemindToCommitSelectedBackersTableDealDetailCommitedTabSuccess: {
    event: 'Bulk Remind To Commit - Selected Backers Table - Deal Detail - Commited Tab - Success',
    source: 'Deal detail page',
    description:
      '"Remind Selected Backers" clicked on live deal detail page table actions for selected backers in commited tab and Successful'
  },
  BulkRemindToCommitSelectedBackersTableDealDetailCommitedTabFailed: {
    event: 'Bulk Remind To Commit - Selected Backers Table - Deal Detail - Commited Tab - Failed',
    source: 'Deal detail page',
    description:
      '"Remind Selected Backers" clicked on live deal detail page table actions for selected backers in commited tab and Failed'
  },
  ManageCommitBackersTableDealDetailPage: {
    event: 'Manage Commit - Invited Tab - Backers Table - Deal Detail Page',
    source: 'Deal detail page',
    description: '"Manage Commit" clicked on live deal detail page table actions for backer in invited tab'
  },
  ManageCommitBackersTableDealDetailPageSuccess: {
    event: 'Manage Commit - Invited Tab - Backers Table - Deal Detail Page - Success',
    source: 'Deal detail page',
    description:
      '"Manage Commit" clicked on live deal detail page table actions for backer in invited tab and Successful'
  },
  ManageCommitBackersTableDealDetailPageFailed: {
    event: 'Manage Commit - Invited Tab - Backers Table - Deal Detail Page - Failed',
    source: 'Deal detail page',
    description: '"Manage Commit" clicked on live deal detail page table actions for backer in invited tab and Failed'
  },
  SendDealInviteToBackers: {
    event: 'Send Deal Invite To Backers',
    source: 'Invite backers to this deal page',
    description: 'Send deal invite to selected backers on "invite backers to this deal" page'
  },
  SendDealInviteToBackersSuccess: {
    event: 'Send Deal Invite To Backers - Success',
    source: 'Invite backers to this deal page',
    description: 'Send deal invite to selected backers on "invite backers to this deal" page and Successful'
  },
  SendDealInviteToBackersFailed: {
    event: 'Send Deal Invite To Backers - Failed',
    source: 'Invite backers to this deal page',
    description: 'Send deal invite to selected backers on "invite backers to this deal" page and Failed'
  },
  ManageCommitInviteBackersToDealPage: {
    event: 'Manage Commit - Invite Backers To Deal  Page',
    source: 'Invite backers to this deal page',
    description: 'Manage commit clicked on invite backers to deal page table actions'
  },
  ManageCommitInviteBackersToDealPageSuccess: {
    event: 'Manage Commit - Invite Backers To Deal Page - Success',
    source: 'Invite backers to this deal page',
    description: 'Manage commit clicked on invite backers to deal page table actions and Successful'
  },
  ManageCommitInviteBackersToDealPageFailed: {
    event: 'Manage Commit - Invite Backers To Deal Page - Failed',
    source: 'Invite backers to this deal page',
    description: 'Manage commit clicked on invite backers to deal page table actions and Failed'
  },
  InvestorDetailsClicksBackersTableInviteBackersToDealPage: {
    event: 'Investor Details Clicks - Backers Table -  Invite Backers To Deal Page',
    source: 'Invite backers to this deal page',
    description: 'Investor name clicked on "invite backers to deal page" table'
  },
  EditDeal_SubmitToLVForReview: {
    event: 'Edit Deal / Submit To LV For Review',
    source: 'Draft deal card',
    description: 'Edit deal / submit to LV for review clicked on draft deal card'
  },
  PublishDealDraftDealCard: {
    event: 'Publish Deal - Draft Deal Card',
    source: 'Draft deal card',
    description: 'Publish deal clicked on draft deal card'
  },
  SchemeDocDownloadDraftDealCard: {
    event: 'Scheme Doc Download - Draft Deal Card',
    source: 'Draft deal card',
    description: 'Scheme doc clicked on draft deal card'
  },
  LeadSchemeDocDownloadDraftDealCard: {
    event: 'Lead Scheme Doc Download - Draft Deal Card',
    source: 'Draft deal card',
    description: 'Lead scheme doc clicked on draft deal card'
  },
  SaveAsDraftStartupInfoSuccess: {
    event: 'SaveAsDraft Startup Info - Success',
    source: 'Startup info form',
    description: 'Save as draft clicked on startup info form page and Successful'
  },
  SaveAsDraftStartupInfoFailed: {
    event: 'SaveAsDraft Startup Info - Failed',
    source: 'Startup info form',
    description: 'Save as draft clicked on startup info form page and Failed'
  },
  SaveAsDraftSchemeInfoSuccess: {
    event: 'SaveAsDraft Scheme Info - Success',
    source: 'Scheme info form',
    description: 'Save as draft clicked on scheme info form page and Successful'
  },
  SaveAsDraftSchemeInfoFailed: {
    event: 'SaveAsDraft Scheme Info - Failed',
    source: 'Scheme info form',
    description: 'Save as draft clicked on scheme info form page and Failed'
  },
  SubmitToLvSchemeInfoSuccess: {
    event: 'SubmitToLv Scheme Info - Success',
    source: 'Scheme info form',
    description: '"Submit to LV" clicked on scheme info form page and Successful'
  },
  SubmitToLvSchemeInfoFailed: {
    event: 'SubmitToLv Scheme Info - Failed',
    source: 'Scheme info form',
    description: '"Submit to LV" clicked on scheme info form page and Failed'
  },
  InvestorToPayImmediateDrawdownYesNo: {
    event: 'Investor To Pay Immediate Drawdown Yes No',
    source: 'Scheme info form',
    description: 'Investor To Pay Immediate Drawdown Yes No clicked scheme info form page'
  },
  EditProfileClicked: {
    event: 'Edit Profile Clicked',
    source: 'Settings page',
    description: 'Edit profile clicked on settings page'
  },
  EditProfileSuccess: {
    event: 'Edit Profile Success',
    source: 'Edit profile page',
    description: 'Edit profile submitted on edit profile page and Successful'
  },
  EditProfileFailed: {
    event: 'Edit Profile Failed',
    source: 'Edit profile page',
    description: 'Edit profile submitted on edit profile page and Failed'
  },
  AddAdminClicked: {
    event: 'Add Admin Clicked',
    source: 'Settings page',
    description: 'Add admin clicked on settings page'
  },
  AddAdminSuccess: {
    event: 'Add Admin Success',
    source: 'Add admin modal',
    description: 'Add admin submitted on add admin modal and Successful'
  },
  AddAdminFailed: {
    event: 'Add Admin Failed',
    source: 'Add admin modal',
    description: 'Add admin submitted on add admin modal and Failed'
  },
  DeleteAdminClicked: {
    event: 'Delete Admin Clicked',
    source: 'Settings page',
    description: 'Delete Admin clicked on settings page admins table'
  },
  DeleteAdminSuccess: {
    event: 'Delete Admin Success',
    source: 'Delete admin modal',
    description: 'Delete admin submitted on verify modal and Successful'
  },
  DeleteAdminFailed: {
    event: 'Delete Admin Failed',
    source: 'Delete admin modal',
    description: 'Delete admin submitted on verify modal and Failed'
  }
};
export default PHE;
